import "react-multi-carousel/lib/styles.css";
import "./slider.css";

import React, { Fragment } from "react";

import Section from "./section";
import Sliders from './sliders';
import UAParser from "ua-parser-js";

const Slider = () => {
  return (
    <Fragment>
      <Section>
        <Sliders />
      </Section>
    </Fragment>
  );
};
Slider.getInitialProps = ({ req }) => {
  let userAgent;
  if (req) {
    userAgent = req.headers["user-agent"];
  } else {
    userAgent = navigator.userAgent;
  }
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";
  return { deviceType };
};
export default Slider;
