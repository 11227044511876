import "./slider.css";

import Anklogo from 'images/Anklogo.svg';
import { Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import React from "react";
import Sutherland from 'images/sutherLandSlider.svg'
import gogovan from 'images/gogovan.svg';
import suiggle from 'images/suiggle.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1200, min: 576 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1
  }
};

const Sliders = ({ deviceType }) => {
  return (
    <Carousel
      arrows={false}
      ssr
      autoPlay
      infinite
      autoPlaySpeed={5000}
      partialVisbile
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      <div class="image-container increase-size">
        <Card className="sliderCard">
          <Card.Img variant="top" src={gogovan} id="sliderImg" />
          <Card.Body>
            <Card.Title className='Title'>Gogovan</Card.Title>
            <Card.Text className="Quote">
              QuotaCal implementation is one of the many initiativeswe tooklast year to streamline our operations, especially in salesand marketing. its going good so far and our managers are finding it useful.
            </Card.Text>
            <Card.Text className="Position">
              Eugene Lee | Cheif Operating Officer
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="increase-size">
        <Card className="sliderCard">
          <Card.Img variant="top" src={Sutherland} id="sliderImg" />
          <Card.Body>
            <Card.Title className='Title'>Sutherland</Card.Title>
            <Card.Text className="Quote">
              QuotaCal is a fine tool for any sales process. It is especially very useful when your organisation is sales driven (and not marketing driven) and you are working with B2B clients in enterprise category. We have found value in the tool.
            </Card.Text>
            <Card.Text className="Position">
              Patricia Lopes | Sales Operations Manager
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="image-container increase-size">
        <Card className="sliderCard">
          <Card.Img variant="top" src={Anklogo} id="sliderImg" />
          <Card.Body>
            <Card.Title className='Title'>ANK Insurrance</Card.Title>
            <Card.Text className="Quote">
              Earlier our sales system was only limited to CRM. With the adoption of QuotaCal, the process has become robust and we are witnessing hyper growth in our sales figures because of the enhanced motivation of sales reps.                        </Card.Text>
            <Card.Text className="Position">
              David Liberati | Chief Business Offices
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="image-container increase-size">
        <Card className="sliderCard">
          <Card.Img variant="top" src={suiggle} id="sliderImg" />
          <Card.Body>
            <Card.Title className='Title'>Just a Squiggle</Card.Title>
            <Card.Text className="Quote">
              QuotaCal is a nice addition to our existing software stack as it is providing immense value to our organisation.
            </Card.Text>
            <Card.Text className="Quote">
              I recommend the tool to all sales leaders.
            </Card.Text>
            <Card.Text className="Position">
              Camila Borda Kaul | Camila Borda Kaul
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

    </Carousel>
  )
}

export default Sliders;
